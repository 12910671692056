import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { SectionFeature as DSSectionFeature } from '@elseu/sdu-titan-web-commerce';
import { Icon } from 'components/Icon';
import type { SectionProps } from 'components/Section';
import type { SectionFeature as ContentfulSectionFeature } from 'generated/graphql';
import { linkParser } from 'utilities/linkParser';

type SectionFeatureProps = Pick<ContentfulSectionFeature, 'featuresCollection' | 'name'> &
  SectionProps;

type Feature = {
  description?: string;
  icon?: React.ReactNode;
  title: string;
  button?: {
    label: string;
    variant?: ButtonVariant;
    href: string;
  };
};

export const SectionFeature: React.FC<SectionFeatureProps> = ({
  featuresCollection,
  name,
  id,
  background,
}) => {
  if (!featuresCollection) return null;

  const features: Feature[] = featuresCollection.items
    .filter((feature) => feature.title !== undefined)
    .map((feature) => ({
      description: feature.description,
      icon: feature.icon && <Icon icon={feature.icon} />,
      title: feature.title!,
      button: feature.button && {
        label: feature.button.label!,
        variant: feature.button.variant as ButtonVariant,
        external: feature.button.external,
        href: linkParser({
          contentfulPage: feature.button.contentfulPage,
          url: feature.button.url,
          anchorLink: feature.button.anchorLink,
          asset: feature.button.asset,
        })!,
      },
    }));

  return (
    <DSSectionFeature
      features={features}
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
    />
  );
};
