import { SectionContent as DSSectionContent } from '@elseu/sdu-titan-web-commerce';
import { Content } from 'components/Content';
import { ContentSplit } from 'components/ContentSplit';
import type { SectionProps } from 'components/Section';
import type { SectionContent as ContentfulSectionContent } from 'generated/graphql';

type SectionContentProps = Pick<ContentfulSectionContent, 'title' | 'contentCollection' | 'name'> &
  SectionProps;

const componentMapping = {
  Content,
  ContentSplit,
};

export const SectionContent: React.FC<SectionContentProps> = ({
  title,
  contentCollection,
  name,
  id,
  background,
}) => (
  <DSSectionContent
    title={title}
    name={name}
    reference={id}
    background={background as 'sand' | 'white'}
  >
    {contentCollection?.items.map((sectionContentItem) => {
      if (!sectionContentItem.__typename) return null;

      const Component = componentMapping[sectionContentItem.__typename];

      return <Component key={sectionContentItem.sys.id} {...sectionContentItem} />;
    })}
  </DSSectionContent>
);
