import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { Content as DSContent } from '@elseu/sdu-titan-web-commerce';
import { RichText } from 'components/RichText';
import type { Content as ContentfulContent } from 'generated/graphql';
import { linkParser } from 'utilities/linkParser';

/**
 * ContentProps is a type representing a subset of ContentfulContent properties
 * used to create the Content component.
 */
type ContentProps = Pick<ContentfulContent, 'content' | 'title' | 'button'>;

/**
 * Content is a custom wrapper component around DSContent that takes in a set of
 * Contentful content, title, and button properties, and renders them using
 * the DSContent and RichText components.
 *
 * @param {ContentProps} props - The properties for the custom Content component.
 * @returns {JSX.Element} The rendered custom Content component.
 */
export const Content: React.FC<ContentProps> = ({ content, title, button: contentfulButton }) => {
  const button = contentfulButton && {
    label: contentfulButton.label as string,
    variant: contentfulButton.variant as ButtonVariant,
    external: contentfulButton.external,
    href: linkParser({
      contentfulPage: contentfulButton.contentfulPage,
      url: contentfulButton.url,
      anchorLink: contentfulButton.anchorLink,
      asset: contentfulButton.asset,
    }),
  };

  return (
    <DSContent title={title} button={button}>
      {content && <RichText document={content} />}
    </DSContent>
  );
};
