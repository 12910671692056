import { Quote as DSQuote } from '@elseu/sdu-titan-web-commerce';
import type { Quote as ContentfulQuote } from 'generated/graphql';
import { getAssetApiPath } from 'utilities/getAssetApiPath';

/**
 * QuoteProps is a type representing the properties for the custom Quote component.
 */
type QuoteProps = Pick<ContentfulQuote, 'person' | 'quote'> & {
  /** The variant of the quote. */
  variant?: 'normal' | 'card';
};

/**
 * Quote is a custom wrapper component around DSQuote that takes in a
 * set of ContentfulQuote properties and creates the corresponding
 * personProps object for the DSQuote component.
 *
 * @param {QuoteProps} props - The properties for the custom Quote component.
 * @returns {JSX.Element | null} The rendered custom Quote component or null if not valid.
 */
export const Quote: React.FC<QuoteProps> = ({ person, quote, variant }) => {
  if (!person?.image?.asset?.url || !quote) return null;

  const personProps = {
    name: person.name!,
    image: {
      src: getAssetApiPath(person.image.asset.url),
      labelAlt: person.image.alt as string,
    },
    emailAddress: person.emailAddress,
    subtitle: person.subtitle,
    telephoneNumber: person.telephoneNumber,
  };

  return (
    <DSQuote person={personProps} variant={variant}>
      {quote}
    </DSQuote>
  );
};
