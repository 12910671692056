import { ContentSplit as DSContentSplit } from '@elseu/sdu-titan-web-commerce';
import { Card } from 'components/Card';
import { CardPricing } from 'components/CardPricing';
import { Collage } from 'components/Collage';
import { Content } from 'components/Content';
import type { ContentSplit as ContentfulContentSplit } from 'generated/graphql';

/**
 * ContentSplitProps is a type representing a subset of ContentfulContentSplit properties
 * used to create the ContentSplit component.
 */
type ContentSplitProps = Pick<ContentfulContentSplit, 'contentCollection' | 'name'>;

const componentMapping = {
  Collage,
  Content,
  Card,
  CardPricing,
};

/**
 * ContentSplit is a custom wrapper component around DSContentSplit that takes in a set of
 * contentCollection items, and renders them using the appropriate components.
 *
 * @param {ContentSplitProps} props - The properties for the custom ContentSplit component.
 * @returns {JSX.Element} The rendered custom ContentSplit component.
 */
export const ContentSplit: React.FC<ContentSplitProps> = ({ contentCollection, name }) =>
  contentCollection ? (
    <DSContentSplit name={name}>
      {contentCollection.items.map((item) => {
        if (!item.__typename) return null;

        const Component = componentMapping[item.__typename];

        return <Component key={item.sys.id} {...item} />;
      })}
    </DSContentSplit>
  ) : null;
