import { SectionQuickLink as DSSectionQuickLink } from '@elseu/sdu-titan-web-commerce';
import { QuickLink } from 'components/QuickLink';
import type { SectionProps } from 'components/Section';
import type { SectionQuickLink as ContentfulSectionQuickLink } from 'generated/graphql';

type SectionQuickLinkProps = Pick<
  ContentfulSectionQuickLink,
  'title' | 'description' | 'quickLinksCollection' | 'name'
> &
  SectionProps;

export const SectionQuickLink: React.FC<SectionQuickLinkProps> = ({
  title,
  description,
  quickLinksCollection,
  name,
  id,
  background,
}) =>
  quickLinksCollection ? (
    <DSSectionQuickLink
      title={title}
      description={description}
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
    >
      {quickLinksCollection.items
        .filter(({ contentfulPage, url }) => contentfulPage || url)
        .map((quicklink) => (
          <QuickLink key={quicklink.title} {...quicklink} />
        ))}
    </DSSectionQuickLink>
  ) : null;
