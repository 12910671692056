import { createSlug, SectionFaq as DSSectionFaq } from '@elseu/sdu-titan-web-commerce';
import { RichText } from 'components/RichText';
import type { SectionProps } from 'components/Section';
import type { SectionFaq as ContentfulSectionFaq } from 'generated/graphql';
import getConfig from 'next/config';
import Head from 'next/head';
import React from 'react';
import type { FAQPage, Question } from 'schema-dts';
import { getPlainText } from 'utilities/getPlainText';

const nextConfig = getConfig();
const basePath = nextConfig?.publicRuntimeConfig?.basePath || process.env.BASE_PATH;

type SectionFaqProps = Pick<ContentfulSectionFaq, 'title' | 'questionsCollection' | 'name'> &
  SectionProps;

/**
 * Generates JSON-LD for the FAQ component.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string | undefined} params.title - The optional title of the FAQ section.
 * @param {Object} params.questions - The questions and answers in the FAQ section.
 * @returns {FAQPage} - The JSON-LD representation of the FAQ component.
 */

const generateFaqJsonLd = ({
  title,
  questions,
  url,
}: {
  title?: string;
  questions: SectionFaqProps['questionsCollection'];
  url?: string;
}): FAQPage => {
  const mainEntity: Question[] = questions!.items.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: getPlainText(answer?.json),
      url: url && question ? `${basePath}${url}#q-${createSlug(question)}` : undefined,
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
    name: title || 'Veelgestelde vragen',
  } as FAQPage;
};

export const SectionFaq: React.FC<SectionFaqProps> = ({
  title,
  questionsCollection,
  name,
  id,
  background,
  url,
}) => {
  const questions = questionsCollection?.items
    .map(
      ({ question, answer }) =>
        !!(question && answer) && {
          header: question,
          content: <RichText document={answer} />,
        },
    )
    .filter(Boolean) as Array<{ header: string; content: React.ReactNode }>;
  const faqJsonLd = generateFaqJsonLd({
    title,
    questions: questionsCollection,
    url,
  });

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqJsonLd) }}
        />
      </Head>
      <DSSectionFaq
        title={title}
        questions={questions}
        name={name}
        reference={id}
        background={background as 'sand' | 'white'}
      />
    </>
  );
};
