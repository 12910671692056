import type { TitanTheme } from '@elseu/sdu-titan-web-commerce';
import { media } from '@elseu/sdu-titan-web-commerce';
import { css } from 'styled-components';

import checkboxCheckedIcon from '../images/checkbox-checked-icon.svg';
import checkboxIcon from '../images/checkbox-icon.svg';

export const eaadPlaceholder = ({ theme }: { theme: TitanTheme }) => css`
  #sdu-eaad-placeholder {
    max-width: 600px;

    & > div {
      label,
      input,
      p,
      h2,
      button {
        display: block;
      }

      label,
      input {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: ${theme.spacing[4]};
      }

      input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + label::before {
          background-image: url(${checkboxCheckedIcon.src});
        }
      }

      input[type='checkbox'] + label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding-left: 24px;
        grid-column: 1/3;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 3px;
          width: 18px;
          height: 18px;
          background-image: url(${checkboxIcon.src});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .sdu-eaad-soft-required-error {
        color: ${theme.designTokens.colors.danger50};
      }

      ${media.greaterThan('s')`
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 10px;

        label {
          grid-column: 1;
          grid-row: auto;
          align-self: center;
        }

        input {
          grid-column: 2;
          grid-row: auto;
        }

        p,
        h2,
        button {
          grid-column: 1 / -1;
          grid-row: auto;
          margin-right: auto;
        }

        button {
          margin-right: 0;
        }
      `}
    }
  }
`;
