import type { TitanTheme } from '@elseu/sdu-titan-web-commerce';
import { textStyle } from '@elseu/sdu-titan-web-commerce';
import { css } from 'styled-components';

import checkboxCheckedIcon from '../images/checkbox-checked-icon.svg';
import checkboxIcon from '../images/checkbox-icon.svg';
import chevronDownIcon from '../images/chevron-down-icon.svg';
import errorIcon from '../images/error-icon.svg';
import okIcon from '../images/ok-icon.svg';

export const eaadLeadgeneration = ({ theme }: { theme: TitanTheme }) => css`
  #sdu-eaad-leadgeneration {
    max-width: calc(768px - ${theme.spacing[8]});

    #sdu-eaad-lead-gen-submit-btn {
      width: auto;
      position: relative;
      outline: none;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      border: 0;
      line-height: 1.25;
      border-radius: ${theme.components.button.borderRadius};
      background-color: ${theme.components.button.variants.primary.backgroundColor};
      color: ${theme.components.button.variants.primary.textColor};
      padding: ${theme.components.button.sizes.L.padding};
      ${textStyle('labelLarge')};

      &:hover {
        cursor: pointer;
        background-color: ${theme.components.button.variants.primary.backgroundColorHover};
        box-shadow: ${theme.components.button.boxShadowHover};
      }

      &:disabled {
        pointer-events: none;
        color: ${theme.components.button.variants.primary.textColorDisabled};
        background-color: ${theme.components.button.variants.primary.backgroundColorDisabled};
      }

      svg {
        flex: 0 0 auto;
      }

      &:focus {
        box-shadow: inset 0 0 0 1px ${theme.components.button.variants.primary.backgroundColor},
          inset 0 0 0 2px ${theme.components.button.variants.primary.borderFocus};
      }
    }

    .sdu-eaad-lead-gen-error {
      ${textStyle('label')}
      color: ${theme.designTokens.colors.danger50};
      left: 30%;
      margin-top: 0;
      position: relative;
    }

    i.widget-icon.widget-error {
      color: ${theme.designTokens.colors.danger50};
      position: relative;

      &::before {
        content: '';
        background-image: url(${errorIcon.src});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 18px;
        height: 18px;
        pointer-events: none;
      }
    }

    i.widget-icon.widget-passed {
      color: #10b910;
      position: relative;

      &::before {
        content: '';
        background-image: url(${okIcon.src});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 18px;
        height: 18px;
        pointer-events: none;
      }
    }

    #sdu-eaad-leadgeneration-start {
      label {
        display: inline-block;
        margin-bottom: ${theme.spacing[8]};
      }

      input {
        display: inline-block;
        padding-right: 40px;
        width: 70%;
        border: 1px solid ${theme.designTokens.colors.grey30};
      }

      select {
        position: relative;
        width: 70%;
        cursor: pointer;
        appearance: none;
        padding: ${theme.spacing[3]} ${theme.spacing[4]};
        border-radius: ${theme.borderRadius[2]};
        border: 1px solid ${theme.designTokens.colors.grey30};
        padding-right: calc(${theme.spacing[4]} + 20px);

        background-image: url(${chevronDownIcon.src});
        background-repeat: no-repeat;
        background-position: right ${theme.spacing[4]} center;
      }

      input[type='radio'] {
        margin-right: 10px;
        position: relative;
        width: 16px;
      }

      input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + label::before {
          background-image: url(${checkboxCheckedIcon.src});
        }
      }

      input[type='checkbox'] + label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding-left: 24px;
        width: 100%;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 3px;
          width: 18px;
          height: 18px;
          background-image: url(${checkboxIcon.src});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      span {
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start label {
      display: block;
      margin-bottom: ${theme.spacing[2]};
      width: 100%;
    }

    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start label[for] + input {
      margin-bottom: ${theme.spacing[6]};
    }

    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start input,
    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start select {
      width: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    #sdu-eaad-leadgeneration .sdu-eaad-lead-gen-error {
      left: 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: 651px) {
    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start label[for] {
      width: 30%;
    }

    #sdu-eaad-leadgeneration #sdu-eaad-leadgeneration-start label:not([for]) + label:not([for]) {
      margin-left: 30px;
    }
  }
`;
