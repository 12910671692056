import { SectionProduct as DSSectionProduct } from '@elseu/sdu-titan-web-commerce';
import { Card } from 'components/Card';
import { Content } from 'components/Content';
import type { SectionProps } from 'components/Section';
import type { SectionProduct as ContentfulSectionProduct } from 'generated/graphql';

type SectionProductProps = Pick<
  ContentfulSectionProduct,
  'content' | 'productsCollection' | 'name'
> &
  SectionProps;

export const SectionProduct: React.FC<SectionProductProps> = ({
  name,
  id,
  background,
  content,
  productsCollection,
}) => {
  const products = productsCollection?.items.map((product) => (
    <Card key={product.title} isProduct {...product} />
  ));

  return (
    <DSSectionProduct
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
      products={products}
      content={<Content {...content} />}
    />
  );
};
