import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { SectionHeader as DSSectionHeader } from '@elseu/sdu-titan-web-commerce';
import { Collage } from 'components/Collage';
import type { SectionHeader as ContentfulSectionHeader } from 'generated/graphql';
import { linkParser } from 'utilities/linkParser';

type SectionHeaderProps = Pick<
  ContentfulSectionHeader,
  'buttonsCollection' | 'collage' | 'subtitle' | 'title' | 'background' | 'name'
> & {
  id: string;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  buttonsCollection,
  collage,
  background,
  name,
  id,
}) => {
  const buttons = buttonsCollection?.items.map(
    ({ variant, contentfulPage, url, label, anchorLink, asset, external }) => ({
      label: label as string,
      href: linkParser({ contentfulPage, url, anchorLink, asset }),
      variant: variant as ButtonVariant,
      external,
    }),
  );

  return title ? (
    <DSSectionHeader
      title={title}
      subtitle={subtitle}
      buttons={buttons}
      collage={<Collage {...collage} isPriority />}
      background={background as 'sand' | 'white'}
      name={name}
      reference={id}
    />
  ) : null;
};
