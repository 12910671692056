import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { Card as DSCard } from '@elseu/sdu-titan-web-commerce';
import { Image } from 'components/Image';
import type { Card as ContentfulCard } from 'generated/graphql';
import { getImageSizes } from 'utilities/getImageSizes';
import { linkParser } from 'utilities/linkParser';

type CardProps = Pick<
  ContentfulCard,
  'button' | 'description' | 'image' | 'price' | 'subtitle' | 'title'
> & {
  /** Whether the card represents a product. */
  isProduct?: boolean;
};

/**
 * Card component to display content in a structured and visually appealing way.
 *
 * @component
 * @param {CardProps} props - Props to configure the card.
 * @param {boolean} props.isProduct - Whether the card represents a product.
 * @returns {React.FC<CardProps>} The Card component.
 */
export const Card: React.FC<CardProps> = ({
  image,
  title,
  subtitle,
  price,
  description,
  button,
  isProduct,
}) => {
  const buttonProps = button && {
    label: button.label as string,
    variant: button.variant as ButtonVariant,
    external: button.external,
    href: linkParser({
      url: button.url,
      contentfulPage: button.contentfulPage,
      anchorLink: button.anchorLink,
      asset: button.asset,
    }),
  };

  return title ? (
    <DSCard
      image={<Image {...image} sizes={getImageSizes({ xs: '100vw', m: '33vw' })} />}
      title={title}
      subtitle={subtitle}
      description={description}
      price={price}
      button={buttonProps}
      isProduct={isProduct}
    />
  ) : null;
};
