import { Collage as DSCollage } from '@elseu/sdu-titan-web-commerce';
import { Card } from 'components/Card';
import { Image } from 'components/Image';
import { Quote } from 'components/Quote';
import { Video } from 'components/Video';
import type { Collage as ContentfulCollage } from 'generated/graphql';
import { getImageSizes } from 'utilities/getImageSizes';

/**
 * CollageProps is a type representing a subset of ContentfulCollage properties
 * used to create the Collage component.
 */
type CollageProps = Pick<ContentfulCollage, 'itemsCollection' | 'variant'> & {
  /** Whether the image should be prioritized. */
  isPriority?: boolean;
};

const componentMapping = {
  Card,
  Image,
  Quote,
  Video,
};

/**
 * Collage is a custom wrapper component around DSCollage that takes in a set of
 * Contentful items and their collection and renders them using the appropriate
 * component based on their type.
 *
 * @param {CollageProps} props - The properties for the custom Collage component.
 * @returns {JSX.Element} The rendered custom Collage component.
 */
export const Collage: React.FC<CollageProps> = ({ itemsCollection, variant, isPriority }) => (
  <DSCollage variant={variant}>
    {itemsCollection?.items.map((item) => {
      if (!item.__typename) return null;

      const Component = componentMapping[item.__typename];

      return (
        <Component
          key={item.sys.id}
          {...item}
          {...(item.__typename === 'Quote' ? { variant: 'card' } : {})}
          {...(item.__typename === 'Image'
            ? { isPriority, width: 700, sizes: getImageSizes({ xs: '50vw', s: '25vw' }) }
            : {})}
        />
      );
    })}
  </DSCollage>
);
