import { QuickLink as DSQuickLink } from '@elseu/sdu-titan-web-commerce';
import type { QuickLink as ContentfulQuickLink } from 'generated/graphql';
import { linkParser } from 'utilities/linkParser';

type QuickLinkProps = Pick<
  ContentfulQuickLink,
  'title' | 'description' | 'contentfulPage' | 'url'
> & {
  /** The background color of the quick link. */
  background?: 'sand' | 'white';
};

export const QuickLink: React.FC<QuickLinkProps> = ({
  title,
  description,
  contentfulPage,
  url,
  background,
}) => {
  const href = linkParser({ contentfulPage, url });

  return title && href ? (
    <DSQuickLink title={title} description={description} href={href} background={background} />
  ) : null;
};
