import { SectionHighlight as DSSectionHighlight } from '@elseu/sdu-titan-web-commerce';
import { Image } from 'components/Image';
import type { SectionHighlight as ContentfulSectionHighlight } from 'generated/graphql';
import { getImageSizes } from 'utilities/getImageSizes';
import { linkParser } from 'utilities/linkParser';

type SectionHighlightProps = Pick<
  ContentfulSectionHighlight,
  'button' | 'description' | 'image' | 'title' | 'variant' | 'highlightBackground' | 'name'
> & {
  id: string;
};

export const SectionHighlight: React.FC<SectionHighlightProps> = ({
  title,
  description,
  image,
  variant,
  highlightBackground,
  button: contentfulButton,
  name,
  id,
}) => {
  const button = {
    label: contentfulButton?.label as string,
    variant: contentfulButton?.variant as string,
    external: contentfulButton?.external,
    href: linkParser({
      contentfulPage: contentfulButton?.contentfulPage,
      url: contentfulButton?.url,
      anchorLink: contentfulButton?.anchorLink,
      asset: contentfulButton?.asset,
    }),
  };

  return title ? (
    <DSSectionHighlight
      title={title}
      description={description}
      image={
        <Image
          {...image}
          sizes={
            variant === 'full'
              ? getImageSizes({ xs: '100vw' })
              : getImageSizes({ xs: '100vw', s: '50vw' })
          }
        />
      }
      variant={variant as 'split' | 'full' | 'collage'}
      highlightBackground={highlightBackground as 'purple' | 'sand'}
      button={button}
      name={name}
      reference={id}
    />
  ) : null;
};
