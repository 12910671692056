import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { Card as DSCard, SectionCard as DSSectionCard } from '@elseu/sdu-titan-web-commerce';
import { Image } from 'components/Image';
import type { SectionProps } from 'components/Section';
import type {
  Document,
  SectionLegacyContent as ContentfulSectionLegacyContent,
} from 'generated/graphql';

type SectionLegacyContentProps = Pick<
  ContentfulSectionLegacyContent,
  'description' | 'title' | 'name' | 'types' | 'imagesCollection' | 'categories'
> &
  SectionProps & {
    documents?: Document[];
  };

export const SectionLegacyContent: React.FC<SectionLegacyContentProps> = ({
  title,
  description,
  name,
  id,
  background,
  categories,
  documents,
  imagesCollection,
}) => {
  const button = {
    label: 'Bekijk meer artikelen',
    href: `/content/archief/${categories?.join('/')}`,
    variant: 'secondary' as ButtonVariant,
  };

  return (
    <DSSectionCard
      title={title}
      description={description}
      button={button}
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
    >
      {documents?.map(({ id, title, types, slug, categories }, index) => (
        <DSCard
          key={id}
          title={title}
          image={
            imagesCollection?.items[index] ? (
              <Image {...imagesCollection.items[index]} />
            ) : undefined
          }
          subtitle={categories.map((category) => category.name).join(', ')}
          label={types.map((type) => type.name).join(', ')}
          button={{
            label: 'Lees meer',
            href: `/content/${slug}`,
            variant: 'link',
          }}
        />
      ))}
    </DSSectionCard>
  );
};
