import type { ButtonVariant } from '@elseu/sdu-titan-web-commerce';
import { SectionCard as DSSectionCard } from '@elseu/sdu-titan-web-commerce';
import { Card } from 'components/Card';
import { CardPricing } from 'components/CardPricing';
import type { SectionProps } from 'components/Section';
import type { SectionCard as ContentfulSectionCard } from 'generated/graphql';
import { linkParser } from 'utilities/linkParser';

type SectionCardProps = Pick<
  ContentfulSectionCard,
  'cardsCollection' | 'description' | 'slideshow' | 'title' | 'button' | 'name'
> &
  SectionProps;

const componentMapping = {
  Card,
  CardPricing,
};

export const SectionCard: React.FC<SectionCardProps> = ({
  title,
  description,
  slideshow,
  button,
  cardsCollection,
  name,
  id,
  background,
}) => {
  const buttonProps = button && {
    label: button.label as string,
    variant: button.variant as ButtonVariant,
    external: button.external,
    href: linkParser({
      url: button.url,
      contentfulPage: button.contentfulPage,
      anchorLink: button.anchorLink,
      asset: button.asset,
    }),
  };

  return (
    <DSSectionCard
      title={title}
      description={description}
      isSlideshow={slideshow}
      button={buttonProps}
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
    >
      {cardsCollection?.items.map((card) => {
        if (!card.__typename) return null;

        const Component = componentMapping[card.__typename];

        return <Component key={card.sys.id} {...card} />;
      })}
    </DSSectionCard>
  );
};
