import { SectionNavigation as DSSectionNavigation } from '@elseu/sdu-titan-web-commerce';

type SectionNavigationProps = {
  id: string;
  background?: string;
};

export const SectionNavigation: React.FC<SectionNavigationProps> = ({ id, background }) => {
  return <DSSectionNavigation reference={id} background={background as 'sand' | 'white'} />;
};
