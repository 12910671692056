import { SectionQuote as DSSectionQuote } from '@elseu/sdu-titan-web-commerce';
import { Quote } from 'components/Quote';
import type { SectionProps } from 'components/Section';
import type { SectionQuote as ContentfulSectionQuote } from 'generated/graphql';

type SectionQuoteProps = Pick<ContentfulSectionQuote, 'quotesCollection' | 'size' | 'name'> &
  SectionProps;

export const SectionQuote: React.FC<SectionQuoteProps> = ({
  quotesCollection,
  size,
  name,
  id,
  background,
}) => (
  <DSSectionQuote
    size={size}
    name={name}
    reference={id}
    background={background as 'sand' | 'white'}
  >
    {quotesCollection?.items.map((quote) => (
      <Quote key={quote.quote} {...quote} />
    ))}
  </DSSectionQuote>
);
