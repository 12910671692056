import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { Block } from '@contentful/rich-text-types';

/**
 * Converts a given Contentful Rich Text document to a plain text string.
 *
 * @param {Block} document - The Contentful Rich Text document to convert.
 * @returns {string} The plain text representation of the document.
 */
export const getPlainText = (document: Block): string => documentToPlainTextString(document);
