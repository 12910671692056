import { SectionVideo as DSSectionVideo } from '@elseu/sdu-titan-web-commerce';
import type { SectionProps } from 'components/Section';
import { Video } from 'components/Video';
import type { SectionVideo as ContentfulSectionVideo } from 'generated/graphql';

type SectionVideoProps = Pick<
  ContentfulSectionVideo,
  'title' | 'name' | 'description' | 'video' | 'split'
> &
  SectionProps;

export const SectionVideo: React.FC<SectionVideoProps> = ({
  name,
  id,
  title,
  description,
  background,
  video,
  split,
}) =>
  title ? (
    <DSSectionVideo
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
      title={title}
      isSplit={split}
      description={description}
      video={<Video {...video} />}
    />
  ) : null;
