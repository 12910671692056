import { SectionPeople as DSSectionPeople } from '@elseu/sdu-titan-web-commerce';
import { Person } from 'components/Person';
import type { SectionProps } from 'components/Section';
import type { SectionPeople as ContentfulSectionPeople } from 'generated/graphql';

type SectionPeopleProps = Pick<
  ContentfulSectionPeople,
  'title' | 'description' | 'peopleCollection' | 'name'
> &
  SectionProps;

export const SectionPeople: React.FC<SectionPeopleProps> = ({
  title,
  description,
  peopleCollection,
  name,
  id,
  background,
}) => {
  const people = peopleCollection?.items.map(
    ({ name, image, emailAddress, subtitle, telephoneNumber }) => (
      <Person
        key={name}
        name={name}
        image={image}
        emailAddress={emailAddress}
        subtitle={subtitle}
        telephoneNumber={telephoneNumber}
        orientation="vertical"
      />
    ),
  );

  return (
    <DSSectionPeople
      title={title}
      description={description}
      name={name}
      reference={id}
      background={background as 'sand' | 'white'}
    >
      {people}
    </DSSectionPeople>
  );
};
