import type { ButtonVariant, TitanTheme } from '@elseu/sdu-titan-web-commerce';
import { CardPricing as DSCardPricing } from '@elseu/sdu-titan-web-commerce';
import { IconList } from 'components/IconList';
import type { CardPricing as ContentfulCardPricing } from 'generated/graphql';
import { useTheme } from 'styled-components';
import { linkParser } from 'utilities/linkParser';

type CardPricingProps = Pick<
  ContentfulCardPricing,
  'button' | 'price' | 'subtitle' | 'title' | 'usps'
>;

/**
 * CardPricing component to display pricing information in a structured and visually appealing way.
 *
 * @param {CardPricingProps} props - Props to configure the card pricing.
 * @returns {React.FC<CardPricingProps>} The CardPricing component.
 */
export const CardPricing: React.FC<CardPricingProps> = ({
  title,
  subtitle,
  price,
  button,
  usps,
}) => {
  const theme = useTheme() as TitanTheme;
  const buttonProps = button && {
    label: button.label as string,
    variant: button.variant as ButtonVariant,
    external: button.external,
    href: linkParser({
      url: button.url,
      contentfulPage: button.contentfulPage,
      anchorLink: button.anchorLink,
      asset: button.asset,
    }),
  };

  return title ? (
    <DSCardPricing
      title={title}
      subtitle={subtitle}
      price={price}
      usps={
        usps ? (
          <IconList
            itemsCollection={usps.itemsCollection}
            iconColor={theme.designTokens.colors.success70}
          />
        ) : null
      }
      button={buttonProps}
    />
  ) : null;
};
